import React, {useState} from 'react';
import Countdown from '../../modules/countdown/Countdown';
import { useSpring, animated, config } from 'react-spring'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { withTranslation, Trans } from 'react-i18next';



function HomeCountdown(props) {

 
  const propsSpring = useSpring({
     opacity: 1,
     delay: 1000,
     transform: "translate(0px, -50%)",
     from: { opacity: 0, transform: "translate(0%, 1000%)" },
     config: config.slow
   });

  return (

    <animated.div style={propsSpring} className="countdown-bar">

      <h2 class="date-day">2 & 3</h2><br />
      <h2 class="date-month">{props.t('Juli')}</h2>
      <h3>2022</h3>

      <Countdown
        timeTillDate="2022-07-02 13:00"
        timeFormat="MM DD YYYY , h:mm a "
      />

    </animated.div>

  );
}

export default withTranslation()(HomeCountdown);
