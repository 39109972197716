import React from 'react';
import { Helmet } from 'react-helmet'

import { Lines, Planets, Cube, Ripple } from 'react-preloaders2';
import Ticket from "../../blocks/ticket/Ticket";
import './assets/css/index.css';

import TriangleAnimated from "../../blocks/triangle/TriangleAnimated";
import PagePreloader from "../../helpers/PagePreloader";
import { withTranslation, Trans } from 'react-i18next';


import Festival2019 from "./assets/images/alba-festival-history-2019.jpg";
import Festival2021 from "./assets/images/alba-festival-history-2021.jpg";



class History extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

  }

  componentDidMount() {

    this.setState({ loading: false });

  }


  render() {
    return (
      <React.Fragment>

        <Helmet>
          <title>{this.props.pageTitle}</title>
        </Helmet>

        <section id="history" className="inner-wrapper">
          <h1>{this.props.t('History')}</h1>


            <TriangleAnimated right />
            <TriangleAnimated left />
            <TriangleAnimated right bottom />
            <TriangleAnimated left bottom />


          <div className="container">
            <div className="body">
              {this.props.t('Entdecke die besten Impressionen des vergangenen Festivals.')}
            </div>
          </div>




          <div className="container">
            <div className="row">
              <div className="col-6">


                  {/* show btn Modal */}
                  <button type="button" className="" data-bs-toggle="modal" data-bs-target="#history-modal-2019">
                    <img src={Festival2019} className="img-fluid" />
                    <h3>2019</h3>
                  </button>

                  {/* Modal */}
                  <div className="modal fade" id="history-modal-2019" tabIndex="-1" aria-labelledby="history-modal-2019-label" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="history-modal-2019-label">2019</h5>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <img src={Festival2019} className="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </div>

              </div>
              <div className="col-6">

                  {/* show btn Modal */}
                  <button type="button" className="" data-bs-toggle="modal" data-bs-target="#history-modal-2021">
                    <img src={Festival2021} className="img-fluid" />
                    <h3>2021</h3>
                  </button>

                  {/* Modal */}
                  <div className="modal fade" id="history-modal-2021" tabIndex="-1" aria-labelledby="history-modal-2021-label" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="history-modal-2021-label">2021</h5>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <img src={Festival2021} className="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </div>


              </div>
            </div>
          </div>


          <Ticket />

        </section>


        <PagePreloader page={this} />

      </React.Fragment>
    );
  }
}


export default withTranslation()(History);
