import { useState, useEffect, useRef } from 'react'
import { Modal } from 'bootstrap'
import { Link } from "react-router-dom";
import axios from "axios";
import { withTranslation, Trans } from 'react-i18next';
import config from '../../config';
import ArtistPlaceholder from "../../assets/images/placeholder.svg";


function LineupGrid(props) {


  const [artist, setArtist] = useState(null);
  const [session, setSession] = useState(0);
  const [loading, setLoading] = useState(true);
  const [artists, setArtists] = useState(null);
  const [images, setImages] = useState(null);


  useEffect(async () => {

    if(!session) {
      const result = await axios(
        config.backendBase + 'session/token',
      );

      setSession(result.data);

      axios({
        method: 'GET',
        url: config.backendBase + 'jsonapi/node/artist?include=field_grid_image&sort=field_arrangement',
        headers: {
          'Content-Type': 'json',
          'X-CSRF-Token': session,
        }
      })
        .then(res => {
          let artists = res.data.data;
          artists = artists.sort(() => Math.random() - 0.5)
          setArtists(artists);
          setImages(res.data.included);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
        });
    }
  });




  return (
    <>


    {!loading &&
      <>


      <div className="artist-bar container">
        <h2>{props.t('Line-up 2022')}</h2>
        <div className="row">
          {artists.map(function(artist, idx){


            console.log(images);

            var linkHref = '#';

            if(!artist.attributes.field_stay_tuned) {
              linkHref = 'artist/' + artist.id;
            }

             return (
               <div className="col-lg-4 col-md-4 col-sm-6" key={artist.id}>
                 <Link to={linkHref}>
                   {!artist.attributes.field_stay_tuned &&
                     <>
                     <img src={'https://api.alba-festival.ch/' + images.find(x => x.id === artist.relationships.field_grid_image.data.id).attributes.uri.url} className="img-fluid" />

                     </>
                   }
                   {artist.attributes.field_stay_tuned &&
                     <img src={ArtistPlaceholder} className="img-fluid" />
                   }

                   <h3>
                     {artist.attributes.field_stay_tuned &&
                       <>Stay Tuned</>
                     }

                     {!artist.attributes.field_stay_tuned &&
                       <>{artist.attributes.title}</>
                     }
                   </h3>
                 </Link>
               </div>
             )
           })}
        </div>
      </div>

      {/*
      <div className="artist-bar container hide">
        <h2>SAmstag, 4.  september 2021</h2>
        <div className="row">

          {artists.map(function(artist, idx){

            //if(artist.attributes.field_date)

            var saturday = new Date('2021-09-04');
            saturday.setHours('0');
            saturday.setMinutes('0');

            var date = new Date(artist.attributes.field_date);
            date.setHours('0');
            date.setMinutes('0');
            //console.log(date);
            //console.log(saturday);

            return;

            var linkHref = '#';

            if(artist.attributes.title != 'Stay tuned') {
              linkHref = 'artist/' + artist.id;
            }

             return (
               <div className="col-md-3" key={artist.id}>
                 <Link to={linkHref}>
                   {artist.relationships.field_grid_image.data &&
                     <>
                     <img src={'https://api.alba-festival.ch/' + images.find(x => x.id === artist.relationships.field_grid_image.data.id).attributes.uri.url} className="img-fluid" />
                     </>
                   }
                   <h3>{artist.attributes.title}</h3>
                 </Link>
               </div>
             )
           })}

        </div>
      </div>

      <div className="artist-bar container hide">
        <h2>Sonntag, 5.  september 2021</h2>
        <div className="row">
          {artists.map(function(artist, idx){

            var sunday = new Date('2021-09-05');
            sunday.setHours('0');
            sunday.setMinutes('0');

            var date = new Date(artist.attributes.field_date);
            date.setHours('0');
            date.setMinutes('0');
            //console.log(date);
            //console.log(sunday);

            if(sunday.getTime() !== date.getTime()) {
              return;
            }

            var linkHref = '#';

            if(artist.attributes.title != 'Stay tuned') {
              linkHref = 'artist/' + artist.id;
            }

             return (
               <div className="col-md-3" key={artist.id}>
                 <Link to={linkHref}>
                   {artist.relationships.field_grid_image.data &&
                     <>
                     <img src={'https://api.alba-festival.ch/' + images.find(x => x.id === artist.relationships.field_grid_image.data.id).attributes.uri.url} className="img-fluid" />
                     </>
                   }
                   <h3>{artist.attributes.title}</h3>
                 </Link>
               </div>
             )
           })}
        </div>
      </div>*/}
      </>
    }
    </>
  );
}

export default withTranslation()(LineupGrid);
