import { animated, config, Spring, useSpring, useTransition } from 'react-spring'
import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";

import PageLeftPolygon from "./assets/images/pageLeftPolygon.svg";
import PageRightPolygon from "./assets/images/pageRightPolygon.svg";


import PageLeftBottomPolygon from "./assets/images/pageLeftBottomPolygon.svg";
import PageRightBottomPolygon from "./assets/images/pageRightBottomPolygon.svg";



const TriangleAnimated = (props) => {


  const PolygonLeftAnimateProps = useSpring({
    delay: 1900,
    transform: "translateX(0px)",
    from: { transform: "translateX(-250px)" },
    config: config.default
  });

  const PolygonRightAnimateProps = useSpring({
    delay: 1900,
    transform:"translateX(0)",
    from: { transform: "translateX(250px)" },
    config: config.default
  });


    return(
      <>
        {props.left && !props.bottom &&
          <>
            <div className="pageTriangle animatesTriangleLeft">
              <animated.div style={PolygonLeftAnimateProps}>
                <img src={PageLeftPolygon} />
              </animated.div>
            </div>
          </>
        }


        {props.right && !props.bottom &&
          <>
            <div className="pageTriangle animatesTriangleRight">
              <animated.div style={PolygonRightAnimateProps}>
                <img src={PageRightPolygon} />
              </animated.div>
            </div>
          </>
        }



        {props.left && props.bottom &&
          <>
            <div className="pageTriangle animatesTriangleLeft bottom">
              <animated.div style={PolygonLeftAnimateProps}>
                <img src={PageLeftBottomPolygon} />
              </animated.div>
            </div>
          </>
        }

        {props.right && props.bottom &&
          <>
            <div className="pageTriangle animatesTriangleRight bottom">
              <animated.div style={PolygonRightAnimateProps}>
                <img src={PageRightBottomPolygon} />
              </animated.div>
            </div>
          </>
        }



      </>
  )
}


export default TriangleAnimated;
