import React from 'react';
import { Helmet } from 'react-helmet'

import { Lines, Planets, Cube, Ripple } from 'react-preloaders2';
import Ticket from "../../blocks/ticket/Ticket";
import './assets/css/index.css';

import TriangleAnimated from "../../blocks/triangle/TriangleAnimated";
import PagePreloader from "../../helpers/PagePreloader";
import { withTranslation, Trans } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'
import * as AllBrandIcons from '@fortawesome/free-brands-svg-icons'

import VIPImage from "./assets/images/vip-lounges.jpg";
import SkyVIPImage from "./assets/images/sky-vip-lounges.jpg";

import * as qs from 'query-string';


class Lounges extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      parsed: qs.parse(window.location.search)
    };

  }

  componentDidMount() {

    this.setState({ loading: false });

  }


  render() {
    return (
      <React.Fragment>

        <Helmet>
          <title>{this.props.pageTitle}</title>
        </Helmet>

        <section id="lounges" className="inner-wrapper">
          <TriangleAnimated right />
          <TriangleAnimated left />
          <TriangleAnimated right bottom />
          <TriangleAnimated left bottom />



          <h1>{this.props.t('Lounges')}</h1>

          <div className="body container">
            {this.props.t('Erlebe die Acts des alba Festivals auf den besten Plätzen, verbringe den Event in gediegenem Ambiente. Ein ausgewähltes Getränkeangebot wird dir von deinem persönlichen Service-Personal angeboten. In diesen Lounges wirst du rundum verwöhnt – ein garantiert unvergessliches Erlebnis.')}

            <hr />

              <button style={{background: 'none', border: 'none'}} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#planModal-vip">

                <>
                  <img src={VIPImage} class="img-fluid" />
                </>

              </button><br />

              {/* Modal */}
              <div className="modal fade" id="planModal-vip" tabIndex="-1" aria-labelledby="planModalLabel-vip" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="planModalLabel-vip">Plan</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-start">

                      <>
                        <img src={VIPImage} class="img-fluid" />
                      </>



                    </div>

                  </div>
                </div>
              </div>


            {/*  <a href="https://www.seetickets.com/ch/event/alba-festival-2022/hardturm-areal/2293084" target="_blank" className="btn btn-primary btn-tour">
                <FontAwesomeIcon icon={AllLightIcons.faStreetView} /> {this.props.t('Zu den Tickets')}
              </a>*/}

              <a href="#"  className="btn btn-primary btn-tour">
                <FontAwesomeIcon icon={AllLightIcons.faStreetView} /> {this.props.t('Sold Out')}
              </a>



            <hr />



              <button style={{background: 'none', border: 'none'}} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#planModal-sky">

                <>
                  <img src={SkyVIPImage} class="img-fluid" />
                </>

              </button><br />

              {/* Modal */}
              <div className="modal fade" id="planModal-sky" tabIndex="-1" aria-labelledby="planModalLabel-sky" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="planModalLabel-sky">Plan</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-start">

                      <>
                        <img src={SkyVIPImage} class="img-fluid" />
                      </>



                    </div>

                  </div>
                </div>
              </div>


              {/*<a href="https://www.seetickets.com/ch/event/alba-festival-2022/hardturm-areal/2293084" target="_blank" className="btn btn-primary btn-tour">
                <FontAwesomeIcon icon={AllLightIcons.faStreetView} /> {this.props.t('Zu den Tickets')}
              </a>
              <a href={'/skyviplounge-datasheet-' + this.props.i18n.language + '.pdf'} target="_blank" className="btn btn-primary btn-tour">
                <FontAwesomeIcon icon={AllLightIcons.faStreetView} /> {this.props.t('mehr Informationen')}
              </a>
*/}

              <a href="#" className="btn btn-primary btn-tour">
                <FontAwesomeIcon icon={AllLightIcons.faStreetView} /> {this.props.t('Sold Out')}
              </a>






          </div>


        </section>

        <PagePreloader page={this} />

      </React.Fragment>
    );
  }
}


export default withTranslation()(Lounges);
