import React from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import axios from "axios";

import config from '../../config';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import PartnerLeftPolygon from "../../assets/images/partnerLeftPolygon.svg";
import PartnerRightPolygon from "../../assets/images/partnerRightPolygon.svg";



class FooterPartner extends React.Component {

  constructor(props) {
    super(props);


    this.state = {
      loading: true,
      partners: null,
      images: null,
      session: null
    }
  }



  componentDidMount() {




    var component = this;


    if(!component.state.session) {
      const result =  axios(
        config.backendBase + 'session/token',
      ).then(result => {

        //console.log(result);

        component.setState({
            session: result.data
        },() => {



          axios({
            method: 'GET',
            url: config.backendBase + 'jsonapi/node/partner?include=field_image&sort=field_arrangement',
            headers: {
              'Content-Type': 'json',
              'X-CSRF-Token': component.state.session,
            }
          })
            .then(res => {


              component.setState({
                  partners: res.data.data,
                  images: res.data.included,
              },() => {
                component.setState({
                    loading: false
                },() => {
                    //console.log(this.state.name);
                });
              });


            })
            .catch(err => {
              component.setState({
                  loading: false
              },() => {
                  //console.log(this.state.name);
              });
            });

      });



      });


    }

  }

  render() {

    var component = this;

    return (
      <>
      <div className="container">
      {!this.state.loading &&

          <>

          <Swiper
            spaceBetween={50}
          slidesPerView={5}
          observer={true}
          observeParents={true}


            breakpoints={{
              // when window width is >= 640px
              100: {
                slidesPerView: 2,
              },
                // when window width is >= 640px
                576: {
                  slidesPerView: 2,
                },
                // when window width is >= 768px
                768: {
                  slidesPerView: 3,
                },
                992: {
                  slidesPerView: 4,
                },
                1200: {
                  slidesPerView: 5,
                },
              }}

          >


          {this.state.partners.map(function(partner, idx){
             return (
               <SwiperSlide key={idx}>
                   {partner.relationships.field_image.data &&
                     <>
                      <a href={partner.attributes.field_link} target="_blank" title={partner.attributes.title}>
                        <img src={config.backendBase + component.state.images.find(x => x.id === partner.relationships.field_image.data.id).attributes.uri.url} className="img-fluid" />
                      </a>
                     </>
                   }
                 </SwiperSlide>
             )
           })}



            <SwiperSlide key={'partner-04'}>
              <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#partner-modal">
                Your Logo here
              </button>
            </SwiperSlide>
            <SwiperSlide key={'partner-05'}>
              <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#partner-modal">
                Your Logo here
              </button>
            </SwiperSlide>
            <SwiperSlide key={'partner-06'}>
              <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#partner-modal">
                Your Logo here
              </button>
            </SwiperSlide>
          </Swiper>





          </>
      }





  </div>

  <div className="animatesTriangleLeft">
    <img src={PartnerLeftPolygon} />
  </div>

  <div className="animatesTriangleRight">
    <img src={PartnerRightPolygon} />
  </div>

      </>
    );
  }
}


export default FooterPartner;
