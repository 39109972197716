import { useState, useEffect, useRef } from 'react'
import Ticker from 'react-ticker'
import { Modal } from 'bootstrap'
import HomeArtist from './HomeArtist'
import { Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'
import * as AllBrandIcons from '@fortawesome/free-brands-svg-icons'
import { withTranslation, Trans } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import Marquee from "react-fast-marquee";
import Cursor from 'react-cursor-follow'


import HomeArtistSample from "./assets/images/homeArtistSample.jpeg";
import HomeLineupLeftPolygon from "./assets/images/homeLineupLeftPolygon.svg";
import HomeLineupRightPolygon from "./assets/images/homeLineupRightPolygon.svg";
import ArtistPlaceholder from "../../assets/images/placeholder.svg";



import config from '../../config';

function HomeLineup(props) {


  const [speed, setSpeed] = useState(5);
  const [artist, setArtist] = useState(null);
  const [isHover, setIsHover] = useState(false);
  const [session, setSession] = useState(0);
  const [loading, setLoading] = useState(true);
  const [artists, setArtists] = useState(null);
  const [images, setImages] = useState(null);

  SwiperCore.use([Autoplay]);


  useEffect(async () => {

    if(!session) {
      const result = await axios(
        config.backendBase + 'session/token',
      );

      setSession(result.data);

      axios({
        method: 'GET',
        url: config.backendBase + 'jsonapi/node/artist?include=field_grid_image',
        headers: {
          'Content-Type': 'json',
          'X-CSRF-Token': session,
        }
      })
        .then(res => {
          console.log(res);
          let artists = res.data.data;
          artists = artists.sort(() => Math.random() - 0.5)
          setArtists(artists);
          setImages(res.data.included);
          setLoading(false);
        })
        .catch(err => {
          //console.log(err);
          setLoading(false);
        });
    }
  });

  function onMouseEnterHandler(artist) {
    setSpeed(1);
  }

  function onMouseLeaveHandler(artist) {
    setSpeed(5);
  }





  return (
    <div onMouseEnter={() => {setIsHover(true)}} onMouseLeave={() => {setIsHover(false)}}>

    {loading &&
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    }

    {!loading &&
      <>



      <Swiper
        loop={true}
        autoplay={{
                    delay: 5000,
                    disableOnInteraction: false
                }}
        spaceBetween={50}
        slidesPerView={1}

      >


      {artists.map(function(artist, idx){

        console.log(images);
        console.log(artist.relationships.field_grid_image.data.id);

        var linkHref = '#';

        if(!artist.attributes.field_stay_tuned) {
          linkHref = 'artist/' + artist.id;
        }

         return (

           <SwiperSlide key={idx}>
             <Link to={linkHref}>
               {images && artist.relationships.field_grid_image.data.id != 'missing' &&
                 <>
                 <img src={'https://api.alba-festival.ch/' + images.find(x => x.id === artist.relationships.field_grid_image.data.id).attributes.uri.url} className="img-fluid" />
                 </>
               }

               {artist.relationships.field_grid_image.data.id == 'missing' &&
                 <>
                 <img src={ArtistPlaceholder} />
                 </>
               }
               <h4>
                 {artist.attributes.field_stay_tuned &&
                   <>Stay Tuned</>
                 }

                 {!artist.attributes.field_stay_tuned &&
                   <>{artist.attributes.title}</>
                 }
               </h4>
             </Link>
             <button className="btn btn-primary btn-artists d-md-none d-sm-flex d-flex">
               <FontAwesomeIcon icon={AllLightIcons.faPlus} /> {props.t('Alle Artists')}
             </button>
           </SwiperSlide>


         )
       })}

      </Swiper>


      {isHover &&
        <Cursor>
          <Link to='/lineup'>
            <button className="btn btn-primary btn-artists">
              <FontAwesomeIcon icon={AllLightIcons.faPlus} /> {props.t('Alle Artists')}
            </button>
          </Link>
        </Cursor>
      }





      <Marquee velocity={100} resetAfterTries={1} minScale={0.7}>

        <div className="item">
          <h5>LINE-UP</h5>
        </div>
        <div className="item">
          <h5>LINE-UP</h5>
        </div>
        <div className="item">
          <h5>LINE-UP</h5>
        </div>
        <div className="item">
          <h5>LINE-UP</h5>
        </div>
      </Marquee>


      </>
    }


    <div className="animatesTriangleLeft">
      <img src={HomeLineupLeftPolygon} />
    </div>

    <div className="animatesTriangleRight">
      <img src={HomeLineupRightPolygon} />
    </div>

  </div>
  );
}

export default withTranslation()(HomeLineup);
